
import {
  defineComponent,
  ref,
  onMounted,
  createVNode,
} from "vue";
import { notification, Modal } from "ant-design-vue";
import superadminService from "../../services/superadmin";
import router from "../../router";
import { CloseOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";
import {
  EyeOutlined
} from "@ant-design/icons-vue";

export default defineComponent({
  emits: ["updateProfile"],
  components: {
    EyeOutlined,
  },
    setup(props, {emit}) {
        const pageLoading = ref<boolean>(false);
        const agencyLists = ref([]);
        const pageSize = ref(10)
        const currentPage = ref(1)
        const totalNoofAgencies = ref()
        const searchAgency = ref()
        const onShowSizeChange = (current: number, pageSizes: number) => {
          pageSize.value = pageSizes
          // agencyListsStatus()
        }
        const agencyColumns = [
        {
            title: "Name",
            dataIndex: "agency_name",
        },
        {
            title: "Contact Person",
            dataIndex: "contact_person",
        },
        {
            title: "Credits",
            dataIndex: "agency_credits",
        },
        {
            title: "Address",
            dataIndex: "formatted_address"
        },
        {
            title: "Status",
            dataIndex: "status",
            slots: { customRender: "status" },
        },
        {
          title: "Action",
          align: "center",
          slots: { customRender: "action" },
        },
        ];
        const getAllAgencyList = async (value?: any) => {
        pageLoading.value = true;
        try {
            const data = {
                sort: 'id',
                limit: pageSize.value,
                page: currentPage.value,
                name: searchAgency?.value,
                order: 'desc'
            }
            const res = await superadminService.getAllAgency(data);
            if (res.data.data.success) {
              agencyLists.value = res.data.data.agencies.data;
              totalNoofAgencies.value = res.data.data.agencies.total
              for (const resData of agencyLists.value) {
                if (resData.status === 1) {
                  resData.active = true;
                } else {
                  resData.active = false;
                }
                if (!resData.agency_credits) {
                  resData.agency_credits = 0;
                }
              }
            }
            pageLoading.value = false;
        } catch (error: any) {
            if (error.response) {
            // console.log(error.response);
            pageLoading.value = false;
            }
        }
        };
        const viewAgency = async (value?: any) => {
          router.push(`/admin-agency/${value.id}`);
        };
        const changeStatus = async (item?: any) => {
          Modal.confirm({
          title: "Are you sure?",
          icon: createVNode(CloseCircleOutlined),
          content: createVNode(
            "p",
            {},
            "Do you want to change status?"
          ),
          okText: "Change",
          okType: "danger",
          cancelText: "Cancel",
          class: "delte-confirm-modal",
          async onOk() {
            await changeAgencyStatus(item);
          },
          onCancel () {
            item.active = !item.active
          }
        });
      };
      const changeAgencyStatus = async (data: any) => {
        try {
          const params = {
            status: data.active ? 1 : 0
          }
          const res = await superadminService.changeAgencyStatus(data.id, params);
          if (res.data.data.success) {
            notify("Success", res.data.data.message, "success");
          }
        } catch (error: any) {
          if (error.response) {
            // console.log(error.response);
            await notify("Error", error.response.data.data.message, "error");
          }
        }
      };
      const notify = async (
        message: string,
        description: string,
        type: string
      ) => {
        (notification as any)[type]({
          message,
          description,
        });
      };
      const onPageChange = () => {
        agencyListsStatus()
      };
      const agencyListsStatus = () => {
        getAllAgencyList('');
      };
      const onAgencySearch = () => {
        currentPage.value = 1
        agencyListsStatus()
      }
        onMounted(async (value?: any) => {
            getAllAgencyList(value);
            setTimeout(() => {
              emit('updateProfile');
            }, 500);
        });
        return {
            agencyColumns,
            agencyLists,
            viewAgency,
            changeStatus,
            pageLoading,
            pageSize,
            currentPage,
            totalNoofAgencies,
            onShowSizeChange,
            onPageChange,
            searchAgency,
            onAgencySearch
        };
    }
    
});
